import styled from 'styled-components';

const maxWidth = 220;
const minWidth = 154;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const SelectedOption = styled.button`
  min-width: ${minWidth}px;
  max-width: ${maxWidth}px;
  height: 50px;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid var(--primary);
  color: var(--primary);
  font-size: 18px;
  text-transform: uppercase;
  background-color: #fff;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ContainerOptions = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  min-width: ${minWidth}px;
  max-width: ${maxWidth}px;
  padding: 5px;
  border-radius: 6px;
  margin-top: 60px;
  border: 1px solid var(--primary);
  position: absolute !important;
  z-index: 10;
`;

export const Option = styled.div`
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: var(--primary);
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 2px;
  cursor: pointer;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  & + & {
    border-top: 1px solid var(--primary);
  }
`;
