import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  margin-top: 100px;
  padding-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 50%;

  padding-bottom: 50px;
  overflow-y: scroll;
  max-height: 50vh;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    height: 50px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Video = styled.video`
  max-width: 100%;
`;

export const Audio = styled.audio`
  max-width: 100%;
`;

export const AssetItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  margin: 10px;
  cursor: pointer;
  border: ${props => (props.selected ? '3px solid var(--secondary)' : 'none')};
  transform: ${props => (props.selected ? 'scale(1.1)' : 'scale(1)')};
  transition: all 0.4s;
  padding: 5px;
  &:hover {
    transform: scale(1.1);
  }
`;

export const AssetName = styled.span`
  font-size: 20px;
  columns: #333;
  text-align: center;
  margin-top: 5px;
`;

export const Categoria = styled.div`
  width: 200px;
  margin: 5px;
  cursor: pointer;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  transition: all 100ms;
  border: 0.5px solid #d9d9d9;
  span {
    margin-left: 10px;
    font-weight: bold;
  }
  &:hover {
    background-color: var(--primary);
    color: white;
    .text-primary {
      color: white;
    }
  }
`;
