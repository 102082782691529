import React, { useEffect, useMemo, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  Legend
} from 'recharts';

import { Icon } from '@iconify/react';

import {
  Container,
  Square,
  LegendLabel,
  Card,
  AccessDataContainer,
  PieContainer
} from './styles';
import apiLmsManager from '../../services/apiLmsManager';
import Filter from '../../components/Dashboard/Filter';
import openSocket from 'socket.io-client';
import CardInfo from './CardInfo';
import RankingAluno from '../../components/Dashboard/RankingAluno';
import AlunosAtivosLoader from '../../components/Loaders/AlunosAtivosLoader';
import RankingAlunoLoader from '../../components/Loaders/RankingAlunoLoader';
import AcessosLoader from '../../components/Loaders/AcessosLoader';
import AlunosOnlineLoader from '../../components/Loaders/AlunosOnlineLoader';

function Dashboard(props) {
  const [lineChartWidth, setLineChartWidth] = useState(400);

  const [alunosAtivos, setAlunosAtivos] = useState([]);
  const [alunosOnline, setAlunosOnline] = useState(null);
  const [totalAlunosAtivos, setTotalAlunosAtivos] = useState(0);
  const [daysFilter, setDaysFilter] = useState(7);

  const [rankingAlunos, setRankingAlunos] = useState([]);
  const [carregandoRanking, setCarregandoRanking] = useState(true);
  const [acessos, setAcessos] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [ticks, setTicks] = useState([0, 2500, 5000, 7500, 10000]);

  const [filter, setFilter] = useState(
    localStorage.getItem('filter')
      ? JSON.parse(localStorage.getItem('filter'))
      : null
  );

  const [loadingAlunosAtivos, setLoadingAlunosAtivos] = useState(true);
  const [acessosLoading, setAcessosLoading] = useState(true);

  const colors = [
    '#7ed321',
    '#FFCC29',
    '#D32121',
    '#00438A',
    '#4A4A4A',
    '#FFF0BF',
    '#FCFAF0'
  ];

  useEffect(() => {
    const colors = [
      '#7ed321',
      '#FFCC29',
      '#D32121',
      '#00438A',
      '#4A4A4A',
      '#FFF0BF',
      '#FCFAF0'
    ];

    function successGetAlunosAtivos(data) {
      let totalAlunos = 0;
      const alunosAtivos = data.map((item, index) => {
        totalAlunos += item.count;
        return {
          id: item._id,
          label: item._id,
          value: item.count,
          color: colors[index]
        };
      });
      setTotalAlunosAtivos(totalAlunos);
      setAlunosAtivos(alunosAtivos);
    }

    async function getDadosAlunosAtivos() {
      const endDate = new Date();
      const startDate = new Date();
      const diasMs = daysFilter * 24 * 60 * 60 * 1000;
      startDate.setTime(endDate.getTime() - diasMs);
      setLoadingAlunosAtivos(true);

      if (filter && filter.id)
        apiLmsManager
          .get(
            `/progresso/alunos-ativos?tipo=${filter.tipo}&id=${filter.id
            }&subtipo=${filter.subtipo
            }&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
          )
          .then(response => successGetAlunosAtivos(response.data))
          .finally(() => setLoadingAlunosAtivos(false));
      else
        apiLmsManager
          .get(
            `/progresso/alunos-ativos?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
          )
          .then(response => successGetAlunosAtivos(response.data))
          .finally(() => setLoadingAlunosAtivos(false));
    }
    getDadosAlunosAtivos();
  }, [filter, daysFilter]);

  useEffect(() => {
    setCarregandoRanking(true);
    async function getRankingAlunos() {
      const endDate = new Date();
      const startDate = new Date();
      const diasMs = daysFilter * 24 * 60 * 60 * 1000;
      startDate.setTime(endDate.getTime() - diasMs);
      let data = null;
      if (filter)
        data = (
          await apiLmsManager.get(

            `/ranking-aluno/ranking-acesso?limit=10&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}&tipo=${filter.tipo
            }&id=${filter.id}`
          )
        ).data;
      else
        data = (
          await apiLmsManager.get(
            `/ranking-aluno/ranking-acesso?limit=10&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
          )
        ).data;

      const rankingAlunos = data.data.map(aluno => {
        const horas = aluno.tempo > 0 ? Math.trunc(aluno.tempo / 3600) : 0;
        const minutos =
          aluno.tempo > 0 ? Math.ceil((aluno.tempo / 60) % 60) : 0;
        return {
          ...aluno,
          horas,
          minutos
        };
      });
      setRankingAlunos(rankingAlunos);
      setCarregandoRanking(false);
    }
    getRankingAlunos();
  }, [filter, daysFilter]);

  useEffect(() => {
    function successGetAcessos(acessos) {

      let maxAcesso = 0;
      acessos.acessos.forEach(acesso => {
        if (acesso.acessosTotais > maxAcesso) {
          maxAcesso = acesso.acessosTotais;
        }
      });

      if (maxAcesso > 0) {
        const tickSize = (maxAcesso + 500) / 4;
        const ticks = new Array(5).fill(null).map((v, index) => {
          return Math.round(index * tickSize);
        });

        setTicks(ticks);
      } else {
        setTicks([0]);
      }

      if (Array.isArray(acessos.modalidades)) {
        setModalidades(acessos.modalidades);
      } else if (
        typeof acessos.modalidades === 'object' &&
        acessos.modalidades !== null
      ) {
        const modalidades = Object.keys(acessos.modalidades).map(
          key => acessos.modalidades[key]
        );
        setModalidades(modalidades);
      }
      //setModalidades([])
      const dadosAcesso = acessos.acessos;
      setAcessos(dadosAcesso);
    }
    async function getDadosAcessos() {
      const endDate = new Date();
      const startDate = new Date();
      const diasMs = daysFilter * 24 * 60 * 60 * 1000;
      startDate.setTime(endDate.getTime() - diasMs);

      setAcessos([]);
      setAcessosLoading(true);

      if (filter?.tipo === 'subdivisao')
        apiLmsManager
          .get(
            `/acessos?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}&modalidade_id=${filter.id
            }`
          )
          .then(response => successGetAcessos(response.data))
          .finally(() => setAcessosLoading(false));
      else {
        apiLmsManager
          .get(
            `/acessos?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
          )
          .then(response => successGetAcessos(response.data))
          .finally(() => setAcessosLoading(false));
      }
    }
    getDadosAcessos();
  }, [filter, daysFilter]);

  useEffect(() => {
    const cardLineChart = document.getElementById('card-line-chart');
    setLineChartWidth(cardLineChart.clientWidth);
    window.onresize = () => {
      setLineChartWidth(cardLineChart.clientWidth);
    };
  }, []);

  const socket = useMemo(
    () => openSocket(process.env.REACT_APP_LMS_PROGRESSO_SOCKET),
    []
  );

  useEffect(() => {
    apiLmsManager
      .get('/user')
      .then(({ data: user }) => {
        const empresaId = user['empresa_id'];
        localStorage.setItem(
          '@User',
          JSON.stringify({
            username: user.username,
            email: user.email,
            name: user.name
          })
        );

        if (user.type_value === 'ADM_FRANQUEADORA') {
          socket.on(`users-online-${empresaId}`, alunosOnline => {
            setAlunosOnline(alunosOnline);
          });
          socket.emit('get-users-online', null, empresaId, alunosOnline => {
            setAlunosOnline(alunosOnline);
          });
        } else {
          socket.on(`users-online-${user.id_externo_escola}`, alunosOnline => {
            setAlunosOnline(alunosOnline);
          });
          socket.emit(
            'get-users-online',
            user.id_externo_escola,
            empresaId,
            alunosOnline => {
              setAlunosOnline(alunosOnline);
            }
          );
        }
      })
      .catch(() => { });
  }, [socket]);

  const renderLegend = props => {
    const { payload } = props;

    return (
      <ul className="legend-accessdata-chart">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <Icon icon="pepicons-pop:line-x" style={{ marginRight: 5 }} />
            {entry.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Container>
      <Filter
        filter={filter}
        setFilter={setFilter}
        daysFilter={daysFilter}
        setDaysFilter={setDaysFilter}
      />

      <AccessDataContainer>
        <div className="item-access">
          <h1 style={{ marginTop: 20 }}>Dados de acesso</h1>
          {loadingAlunosAtivos ? (
            <AlunosAtivosLoader />
          ) : (
            <CardInfo
              title="Alunos ativos"
              value={totalAlunosAtivos}
              style={{ padding: '30px 30px' }}
              moreInfo={
                <PieContainer>
                  <div id="grafico-torta" style={{ width: 100, height: 100 }}>
                    <ResponsivePie
                      data={alunosAtivos}
                      margin={{
                        top: 1.5,
                        right: 1.5,
                        bottom: 1.5,
                        left: 1.5
                      }}
                      colors={colors}
                      innerRadius={0.8}
                      enableRadialLabels={false}
                      slicesLabelsSkipAngle={10}
                      animate={false}
                      isInteractive={true}
                      enableSliceLabels={false}
                      sliceLabelsTextColor={'#000'}
                    />
                  </div>
                  <div className="legend-pie">
                    {alunosAtivos.map(item => (
                      <LegendLabel key={item.id}>
                        <Square color={item.color} />
                        <label>
                          {item.label}
                          <br />
                          <b>{item.value}</b>
                        </label>
                      </LegendLabel>
                    ))}
                  </div>
                </PieContainer>
              }
            />
          )}
          {alunosOnline === null ? (
            <AlunosOnlineLoader />
          ) : (
            <CardInfo
              icon={<Icon icon="mdi:account-online-outline" />}
              title="Alunos on-line (acessando o conteúdo)"
              value={alunosOnline}
            />
          )}
        </div>
        <div className="item-access">
          <h1 style={{ marginTop: 20, marginBottom: 30 }}>Ranking</h1>

          {carregandoRanking ? (
            <RankingAlunoLoader />
          ) : (
            <RankingAluno
              rankingAlunos={rankingAlunos}
              history={props.history}
            />
          )
          }
        </div >
      </AccessDataContainer >

      <h1 style={{ marginTop: 30, marginBottom: 30 }}>
        Acessos por modalidade e período
      </h1>

      <Card className="border-rounded" id="card-line-chart">
        {acessosLoading ? (
          <AcessosLoader />
        ) : acessos.length > 0 ? (
          <LineChart
            width={lineChartWidth - 80}
            height={300}
            data={acessos}
            margin={{ top: 5, right: 20, left: 80, bottom: 5 }}
          >
            <CartesianGrid stroke="#ccc" />

            <XAxis
              xAxisId={0}
              height={40}
              dataKey="name"
              tick={{ fontSize: 13 }}
              tickLine={true}
            >
              <Label
                value="Período"
                position="left"
                fontSize={13}
                fill="#4a4a4a"
                dx={-20}
                dy={-8}
              />
            </XAxis>

            <XAxis
              xAxisId={1}
              dataKey="acessosTotais"
              tickLine={false}
              tick={{ fontSize: 13, angle: 0, fontWeight: 'bold' }}
              axisLine={false}
            >
              <Label
                value="Acessos Totais"
                position="left"
                fill="#4a4a4a"
                fontSize={13}
                dx={-20}
                dy={-3}
              />
            </XAxis>

            <YAxis ticks={ticks} />

            <Tooltip />
            <Legend
              verticalAlign="top"
              height={30}
              fontFamily="ArialRoundedMt"
              iconType="plainline"
              content={renderLegend}
              wrapperStyle={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            />
            {modalidades.map((modalidade, index) => (
              <Line
                key={modalidade}
                type="monotone"
                dataKey={modalidade}
                stroke={colors[index]}
                strokeWidth={3}
              />
            ))}
          </LineChart>
        ) : !acessosLoading ? (
          <div
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              marginBottom: 50,
              marginTop: 50
            }}
          >
            <span
              style={{
                textAlign: 'center',
                marginTop: 16,
                fontSize: 15,
                fontFamily: 'ArialRoundedMt'
              }}
            >
              Nenhum dado encontrado.
            </span>
          </div>
        ) : (
          <></>
        )}
      </Card>
    </Container>
  );
}

export default Dashboard;
