import styled, { css } from 'styled-components';

export const Container = styled.div`
  .legend-accessdata-chart {
    display: flex;
    li {
      display: flex;
      align-items: center;
      font-family: 'ArialRoundedMt';
      & + li {
        margin-left: 20px;
      }
    }
  }
`;

export const Filtrar = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  select {
    width: 132px;
    margin-left: 18px;
  }
  div {
    position: relative;
  }
  .btn-limpar-filtro {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--danger);
    right: 0px;
    top: -15px;
  }
`;

export const Card = styled.div`
  padding: 0;
  position: relative;
  width: 100%;
  background-color: white;
  min-height: 122px;
  @media screen and (max-width: 1000px) {
    width: 100% !important;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 14px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 50px;
  background-color: #f4f4f2;
  padding: 0;
  overflow: hidden;
  thead {
    background-color: #f4f4f2;
  }
  th {
    color: var(--primary);
    padding: 5px;
    text-align: left;
    font-family: 'ArialRoundedMt';
    font-size: 14px;
    padding-bottom: 14px;
  }

  td {
    text-align: left;
    padding: 6px 5px;
    font-weight: 400;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
    color: #333;

    button.primary {
      height: 27px;
      padding: 0 10px;
      border-radius: 8px;
      font-family: 'ArialRoundedMt', sans-serif;
    }
  }

  tbody {
    border-collapse: separate;
    width: 100%;
    border-radius: 25px;
  }
  tr {
    background-color: var(--primary-10);
    cursor: default;
  }
  tr:nth-child(even) {
    background-color: #f4f4f2;
  }
`;

export const Square = styled.div`
  width: 15px;
  height: 15px;
  background: ${props => (props.color ? props.color : '#000')};
`;

export const LegendLabel = styled.div`
  display: flex;
  label {
    margin-left: 10px;
    font-size: 13px;
    b {
      font-size: 15px;
    }
  }
`;

export const FiltroAcessos = styled.div`
  display: flex;
  .filter-span {
    margin-left: 73px;
    color: #464646;
  }
  margin-bottom: 12px;
`;

export const DaysContainer = styled.div`
  display: flex;
  margin-left: 20px;
`;

export const DayFilter = styled.button`
  border: none;
  background: none;
  font-size: 15px;
  color: ${props => (props.selected ? 'var(--primary)' : '#4a4a4a')};
  font-weight: ${props => (props.selected ? '700' : '400')};
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  .ball {
    width: 8px;
    height: 8px;
    background: ${props => (props.selected ? 'var(--primary)' : 'transparent')};
    border-radius: 50%;
    margin-top: 2px;
  }
`;

export const PeriodButton = styled.button`
  background-color: #ffffff;
  border: none;
  color: var(--primary-70);
  font-family: 'ArialRoundedMt', sans-serif;
  margin-right: 30px;
  ${props =>
    props.active &&
    css`
      color: #ffffff;
      background-color: var(--primary);
    `}
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 40px 0;
  h2 {
    font-family: 'ArialRoundedMt';
    color: var(--primary);
    margin-right: 160px;
    font-size: 22px;
  }
`;

export const AccessDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  .item-access {
    width: calc(50% - 15px);
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
`;

export const PieContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .legend-pie {
    margin-left: 10px;
    max-height: 100px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;
