import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 13px;
  width: 100%;
  justify-content: space-between;
  select {
    width: 132px;
  }
  div {
    position: relative;
  }
  .btn-limpar-filtro {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--danger);
    right: 0px;
    top: -15px;
  }
`;
