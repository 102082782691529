const INITIAL_STATE = {
  onImageSelect: () => {},
  show: false,
  type: 'image'
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_GALERIA_IMAGEM':
      return {
        ...state,
        onImageSelect: action.onImageSelect,
        show: action.show,
        type: action.assetType
      };
    default:
      return state;
  }
}
