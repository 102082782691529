const themes = {
  default: {
    colors: {
      primary: '#0068de',
      secondary: '#1c1c22',
      text: '#f4f4f4',
      green: '#20b84a',
      red: '#e62d19',
      orange: 'rgb(235, 99, 22)',
      light: {
        primary: '#99c3f2'
      }
    }
  },
  live: {
    colors: {
      primary: '#eb6316',
      secondary: '#1c1c22',
      text: '#f4f4f4',
      green: '#20b84a',
      red: '#e62d19',
      orange: 'rgb(235, 99, 22)',
      light: {
        primary: '#f7c1a2'
      }
    }
  },
  online: {
    colors: {
      primary: '#744bfd',
      secondary: '#1c1c22',
      text: '#f4f4f4',
      green: '#20b84a',
      red: '#e62d19',
      orange: 'rgb(235, 99, 22)',
      light: {
        primary: '#c7b7fe'
      }
    }
  },
  presencial: {
    colors: {
      primary: '#0068de',
      secondary: '#1c1c22',
      text: '#f4f4f4',
      green: '#20b84a',
      red: '#e62d19',
      orange: 'rgb(235, 99, 22)',
      light: {
        primary: '#99c3f2'
      }
    }
  }
};

export default themes;
