import React, { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import AppLayout from '../../layouts/App';
import apiLmsProgresso from '../../services/apiLmsProgresso';

import {
  Container,
  HeaderUnitContainer,
  InfoAula,
  InfoAulaContainer,
  UnitContainer,
  Wrapper,
  AulaStatsContainer
} from './styles';

function DadosAula(props) {
  const [aula, setAula] = useState({});

  useEffect(() => {
    async function getInfoAula(idAula) {
      const aula = (await apiLmsProgresso.get(`/relatorio/aula/${idAula}`))
        .data;
      setAula(aula);
    }

    const idAula = props.match.params.idAula;
    if (idAula) {
      getInfoAula(idAula);
    }
  }, [props.match.params.idAula]);

  return (
    <AppLayout
      title={
        <>
          {'Aula '}
          <span>{aula?.nome}</span>
        </>
      }
      {...props}
    >
      <Container>
        <AulaStatsContainer className="border-radius primary">
          <Wrapper>
            <InfoAulaContainer>
              <InfoAula style={{ width: '20%' }}>
                <span className="info">Alunos atualmente nessa aula</span>
                <span className="dado">
                  <b>{aula?.total_alunos}</b>
                </span>
              </InfoAula>
              <InfoAula style={{ width: '40%' }}>
                <span className="info">
                  Porcentagem em comparação com outros alunos nessa unidade
                </span>
                <span className="dado">
                  <b>
                    {!isNaN(aula?.porcentagem_alunos)
                      ? Math.round(aula?.porcentagem_alunos)
                      : 0}
                    %
                  </b>
                </span>
              </InfoAula>
            </InfoAulaContainer>
            <button
              className="btn-info"
              style={{ color: 'white' }}
              onClick={() =>
                props.history.push(`/progresso-alunos-aula/${aula?.id}`)
              }
            >
              VER PROGRESSO POR ALUNO <FaAngleRight />
            </button>
          </Wrapper>
        </AulaStatsContainer>

        {aula.questoes?.map(questao => (
          <>
            <UnitContainer key={questao.id} className="border-radius">
              <HeaderUnitContainer>
                <h2>
                  <b>{`${questao.nome} (${questao.total_alunos} ${
                    questao.total_alunos > 1 ? 'alunos' : 'aluno'
                  })`}</b>
                </h2>
              </HeaderUnitContainer>
              <Wrapper style={{ marginTop: 32 }}>
                <InfoAulaContainer>
                  <InfoAula>
                    <span className="info">
                      Concluida por: <b>{questao.conclusoes} alunos</b>
                    </span>
                    <span className="info">
                      Não iniciada por: <b>{questao.nao_iniciada} alunos</b>
                    </span>
                  </InfoAula>
                  <InfoAula>
                    <span className="info">
                      Média de tentativas:{' '}
                      <b>
                        {!isNaN(questao.media_tentativas)
                          ? Math.round(questao.media_tentativas)
                          : 0}
                      </b>
                    </span>
                    <span className="info">
                      Porcentagem de acerto:{' '}
                      <b>
                        {!isNaN(questao.porcentagem_acerto)
                          ? Math.round(questao.porcentagem_acerto)
                          : 0}
                        %
                      </b>
                    </span>
                  </InfoAula>
                </InfoAulaContainer>
              </Wrapper>
            </UnitContainer>
          </>
        ))}
      </Container>
    </AppLayout>
  );
}

export default DadosAula;
