import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  slides: [
    {
      initial: true,
      backgroundImage: null,
      background: '#fff',
      blocks: {},
      extraWords: []
    }
  ],
  slideIndex: 0,
  blocksSelected: [],
  selectedCell: { line: null, column: null },
  audioAddMode: 'default',
  toast: { show: false, message: '' },
  blockImage: null,
  copiedBlocks: JSON.parse(localStorage.getItem('copied_blocks')),
  isDragging: false,
  inInput: false,
  defaultBackground: { type: 'color', background: '#fff' },
  controlIsPressed: false,
  slideId: null,
  slide: {}
};

export default function(state = INITIAL_STATE, action) {
  let slides = [];
  let blocks;
  let history;
  let block;
  let copiedBlocks;
  let newBlocksIndex;

  function addHistory() {
    const stateAnterior = JSON.parse(JSON.stringify(state));
    let history = JSON.parse(localStorage.getItem('history'));
    if (history) {
      if (history.length < 10) {
        history.push(stateAnterior);
        localStorage.setItem('history', JSON.stringify(history));
      } else {
        history = history.slice(1);
        history.push(stateAnterior);
        localStorage.setItem('history', JSON.stringify(history));
      }
    } else {
      localStorage.setItem('history', JSON.stringify([stateAnterior]));
    }
  }

  function addHistoryRedo() {
    const stateAnterior = JSON.parse(JSON.stringify(state));
    let historyRedo = JSON.parse(localStorage.getItem('history_redo'));
    if (historyRedo) {
      if (historyRedo.length < 10) {
        historyRedo.push(stateAnterior);
        localStorage.setItem('history_redo', JSON.stringify(historyRedo));
      } else {
        historyRedo = historyRedo.slice(1);
        historyRedo.push(stateAnterior);
        localStorage.setItem('history_redo', JSON.stringify(historyRedo));
      }
    } else {
      localStorage.setItem('history_redo', JSON.stringify([stateAnterior]));
    }
  }

  switch (action.type) {
    case actionsTypes.SET_BACKGROUND:
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          background: action.background,
          backgroundImage: null
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_BACKGROUND_IMAGE:
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          background: null,
          backgroundImage: action.background
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.SET_CONTENT:
      state.slides[state.slideIndex].blocks[action.index].content =
        action.content;
      return state;

    case actionsTypes.SET_HTML:
      /*blocks = {
        ...state.slides[state.slideIndex].blocks,
        [action.index]: {
          ...state.slides[state.slideIndex].blocks[action.index],
          contentHtml: action.html
        }
      };
      console.log(action.html);
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };*/
      state.slides[state.slideIndex].blocks[action.index].contentHtml =
        action.html;
      return state;

    case actionsTypes.SET_COLUMN_HTML:
      if (!action.hasAudio)
        state.slides[state.slideIndex].blocks[action.index].table[
          action.indexLine
        ][action.indexColumn].contentHtml = action.html;
      else
        state.slides[state.slideIndex].blocks[action.index].table[
          action.indexLine
        ][action.indexColumn][1].contentHtml = action.html;
      return state;

    case actionsTypes.SET_COLUMN_CONTENT:
      if (!action.hasAudio)
        state.slides[state.slideIndex].blocks[action.index].table[
          action.indexLine
        ][action.indexColumn].content = action.content;
      else
        state.slides[state.slideIndex].blocks[action.index].table[
          action.indexLine
        ][action.indexColumn][1].content = action.content;
      return state;

    case actionsTypes.SET_TABLE:
      blocks = {
        ...state.slides[state.slideIndex].blocks,
        [state.blocksSelected[0]]: {
          ...state.slides[state.slideIndex].blocks[state.blocksSelected[0]],
          table: action.table,
          lines: action.lines,
          columns: action.columns
        }
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.SET_BLOCK_POSITION:
      addHistory();
      slides = [];

      blocks = state.slides[state.slideIndex].blocks;
      blocks[action.index].position = {
        x: action.x,
        y: action.y
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_ARROW_BLOCK_POSITION:
      addHistory();
      slides = [];
      blocks = state.slides[state.slideIndex].blocks;

      state.blocksSelected.forEach(blockIndex => {
        blocks = state.slides[state.slideIndex].blocks;
        blocks[blockIndex].position = {
          x:
            action.keyPressed === 'ArrowRight'
              ? blocks[blockIndex].position.x + 1
              : action.keyPressed === 'ArrowLeft'
              ? blocks[blockIndex].position.x - 1
              : blocks[blockIndex].position.x,
          y:
            action.keyPressed === 'ArrowUp'
              ? blocks[blockIndex].position.y - 1
              : action.keyPressed === 'ArrowDown'
              ? blocks[blockIndex].position.y + 1
              : blocks[blockIndex].position.y
        };
      });

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_BLOCK_SIZE:
      addHistory();
      blocks = state.slides[state.slideIndex].blocks;

      blocks[action.index].size = {
        width: action.width,
        height: action.height
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.SET_SLIDE_INDEX:
      return {
        ...state,
        slideIndex: action.index,
        slide: { conteudo: state.slides[action.index] }
      };

    case actionsTypes.SET_BLOCK_INDEX:
      return { ...state, blocksSelected: [action.index] };

    case actionsTypes.ADD_BLOCK_SELECTED:
      if (state.blocksSelected.includes(action.index)) {
        const indexToRemove = state.blocksSelected.indexOf(action.index);
        return {
          ...state,
          blocksSelected: [
            ...state.blocksSelected.slice(0, indexToRemove),
            ...state.blocksSelected.slice(indexToRemove + 1)
          ]
        };
      }
      return {
        ...state,
        blocksSelected: [...state.blocksSelected, action.index]
      };

    case actionsTypes.SET_CONTROL_IS_PRESSED:
      return { ...state, controlIsPressed: action.isPressed };

    case actionsTypes.DELETE_BLOCK:
      blocks = state.slides[state.slideIndex].blocks;
      delete blocks[action.blockKey];
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.DELETE_SELECTED_BLOCK:
      addHistory();
      blocks = state.slides[state.slideIndex].blocks;

      state.blocksSelected.forEach(blockIndex => {
        block = blocks[blockIndex];

        if (block && block.action && block.action === 'clickable') {
          Object.keys(blocks).map(key => {
            if (
              blocks[key] &&
              blocks[key].clickable &&
              blocks[key].clickable === blockIndex
            )
              delete blocks[key];
            return '';
          });
        }

        if (block.type === 'drop') {
          Object.keys(blocks).forEach(key => {
            if (
              blocks[key] &&
              blocks[key].dropable &&
              blocks[key].dropable === blockIndex
            )
              delete blocks[key];
          });
        }

        if (block.type === 'tic_tac_toe') {
          Object.keys(blocks).forEach(key => {
            if (blocks[key] && blocks[key].tic_tac_toe_key === blockIndex)
              delete blocks[key];
          });
        }

        if (block.type === 'square_tic_tac_toe') {
          Object.keys(blocks).forEach(key => {
            if (
              (blocks[key] &&
                blocks[key].tic_tac_toe_key === block.tic_tac_toe_key) ||
              (blocks[key] && blocks[key].key === block.tic_tac_toe_key)
            )
              delete blocks[key];
          });
        }

        delete blocks[blockIndex];
      });

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides, blocksSelected: [] };

    case actionsTypes.ADD_BLOCK:
      addHistory();
      blocks = {
        ...state.slides[parseInt(state.slideIndex)].blocks,
        [action.blockIndex]: action.block
      };
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks: blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_SELECTED_CELL:
      return { ...state, selectedCell: action.cell };

    case actionsTypes.SET_AUDIO_ADD_MODE:
      return { ...state, audioAddMode: action.mode };

    case actionsTypes.SET_QUIZ_ANSWER_HTML:
      state.slides[state.slideIndex].blocks[action.index].answers[
        action.indexAnswer
      ].contentHtml = action.html;
      return state;

    case actionsTypes.SET_QUIZ_ANSWER_FORMAT:
      state.slides[state.slideIndex].blocks[action.index].answers[
        action.indexAnswer
      ].format = action.format;
      return state;

    case actionsTypes.SET_QUIZ_ANSWER_CONTENT:
      state.slides[state.slideIndex].blocks[action.index].answers[
        action.indexAnswer
      ].content = action.content;
      return state;

    case actionsTypes.ADD_ANSWER_QUIZ:
      blocks = {
        ...state.slides[state.slideIndex].blocks,
        [action.index]: {
          ...state.slides[state.slideIndex].blocks[action.index],
          answers: []
        }
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.SET_SLIDE:
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          id: state.slides[state.slideIndex].id,
          blocks: action.blocks,
          background: action.background,
          backgroundImage: action.backgroundImage,
          controls: action.controls ? action.controls : {},
          overview: action.overview,
          extraWords: [],
          naoSequencial: action.naoSequencial,
          plataforma: action.plataforma,
          publico: action.publico
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      if (state.slideIndex !== undefined) {
        return {
          ...state,
          slides,
          slide: {
            conteudo: slides[state.slideIndex]
          },
          blocksSelected: []
        };
      }

      return {
        ...state,
        slides,
        blocksSelected: []
      };

    case actionsTypes.SET_CONTROLS_SLIDE:
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          controls: action.controls
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.ADD_SLIDE:
      let slide = {};
      if (state.defaultBackground.type === 'color')
        slide = {
          backgroundImage: null,
          background: state.defaultBackground.background,
          blocks: {},
          controls: {},
          extraWords: []
        };
      else
        slide = {
          backgroundImage: state.defaultBackground.background,
          background: null,
          blocks: {},
          controls: {},
          extraWords: []
        };

      if (action.toEnd)
        return {
          ...state,
          slides: [...state.slides, slide],
          slideIndex: state.slides.length
        };
      else {
        slides = [
          ...state.slides.slice(0, parseInt(state.slideIndex) + 1),
          slide,
          ...state.slides.slice(parseInt(state.slideIndex) + 1)
        ];
        return { ...state, slides, slideIndex: parseInt(state.slideIndex) + 1 };
      }

    case actionsTypes.REMOVE_SLIDE:
      if (state.slides.length !== 1) {
        let { slides } = state;
        slides = slides.filter(
          (value, index) => index !== parseInt(state.slideIndex)
        );
        return {
          ...state,
          slides,
          slideIndex:
            parseInt(state.slideIndex) - 1 >= 0
              ? parseInt(state.slideIndex) - 1
              : 0
        };
      } else {
        blocks = {};
        slides = [
          ...state.slides.slice(0, state.slideIndex),
          {
            blocks,
            background: '#fff',
            backgroundImage: null,
            controls: {},
            extraWords: []
          },
          ...state.slides.slice(parseInt(state.slideIndex) + 1)
        ];
        return { ...state, slides, blocksSelected: [] };
      }
    case actionsTypes.SET_SLIDES:
      slides = action.slides;
      return {
        ...state,
        slides,
        slide: { conteudo: slides[state.slideIndex] },
        blocksSelected: []
      };

    case actionsTypes.SET_SLIDE_ID:
      slides = [
        ...state.slides.slice(
          0,
          action.slideIndex ? action.slideIndex : state.slideIndex
        ),
        {
          ...state.slides[
            action.slideIndex ? action.slideIndex : state.slideIndex
          ],
          id: action.id
        },
        ...state.slides.slice(
          parseInt(action.slideIndex ? action.slideIndex : state.slideIndex) + 1
        )
      ];
      return {
        ...state,
        slides
      };

    case actionsTypes.SET_SLIDE_ID_PARAM:
      return {
        ...state,
        slideId: action.slideId
      };

    case actionsTypes.SET_AULA_ID:
      return { ...state, aulaId: action.aulaId };

    case actionsTypes.SET_TOAST:
      return { ...state, toast: action.toast };

    case actionsTypes.SET_BLOCK_IMAGE:
      return { ...state, blockImage: action.blockImage };

    case actionsTypes.SET_HIDE_BLOCK_POSITION:
      slides = [];

      blocks = state.slides[state.slideIndex].blocks;
      blocks[action.blockIndex].hideBlocks[action.hideBlockIndex].position = {
        x: action.x,
        y: action.y
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];
      return { ...state, slides };

    case actionsTypes.SET_HIDE_BLOCK_SIZE:
      blocks = state.slides[state.slideIndex].blocks;

      blocks[action.blockIndex].hideBlocks[action.hideBlockIndex].size = {
        width: action.width,
        height: action.height
      };

      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(parseInt(state.slideIndex) + 1)
      ];

      return { ...state, slides };

    case actionsTypes.COPY_BLOCKS:
      blocks = state.slides[state.slideIndex].blocks;
      copiedBlocks = [];
      Object.keys(blocks).forEach(blockIndex => {
        if (state.blocksSelected.includes(blockIndex))
          copiedBlocks.push({ ...blocks[blockIndex], blockIndex });
      });
      localStorage.setItem('copied_blocks', JSON.stringify(copiedBlocks));
      return {
        ...state,
        copiedBlocks: copiedBlocks
      };

    case actionsTypes.PASTE_BLOCKS:
      copiedBlocks = JSON.parse(JSON.stringify(state.copiedBlocks));
      blocks = state.slides[state.slideIndex].blocks;
      newBlocksIndex = {};

      if (state.copiedBlocks) {
        copiedBlocks.forEach((copiedBlock, index) => {
          const newBlockIndex = `block${index + Date.now()}`;
          if (copiedBlock.type === 'clickable') {
            newBlocksIndex[copiedBlock.blockIndex] = newBlockIndex;
          }
        });

        copiedBlocks.forEach((copiedBlock, index) => {
          const newBlockIndex = `block${index + Date.now()}`;

          if (copiedBlock.clickable) {
            blocks = {
              ...blocks,
              [newBlocksIndex[copiedBlock.blockIndex]
                ? newBlocksIndex[copiedBlock.blockIndex]
                : newBlockIndex]: {
                ...copiedBlock,
                clickable: newBlocksIndex[copiedBlock.clickable]
                  ? newBlocksIndex[copiedBlock.clickable]
                  : null,
                position: {
                  x: copiedBlock.position.x + 1,
                  y: copiedBlock.position.y + 1
                }
              }
            };
          } else {
            blocks = {
              ...blocks,
              [newBlocksIndex[copiedBlock.blockIndex]
                ? newBlocksIndex[copiedBlock.blockIndex]
                : newBlockIndex]: {
                ...copiedBlock,
                position: {
                  x: copiedBlock.position.x + 1,
                  y: copiedBlock.position.y + 1
                }
              }
            };
          }
        });
      } else blocks = { ...state.slides[parseInt(state.slideIndex)].blocks };

      return {
        ...state,
        slides: [
          ...state.slides.slice(0, state.slideIndex),
          {
            ...state.slides[state.slideIndex],
            blocks
          },
          ...state.slides.slice(parseInt(state.slideIndex) + 1)
        ]
      };

    case actionsTypes.UNDO:
      history = JSON.parse(localStorage.getItem('history'));
      if (history[history.length - 1]) {
        let stateAnterior = history[history.length - 1];
        history.pop();
        localStorage.setItem('history', JSON.stringify(history));
        addHistoryRedo();
        return stateAnterior;
      } else return state;

    case actionsTypes.REDO:
      history = JSON.parse(localStorage.getItem('history_redo'));
      if (history[history.length - 1]) {
        let stateAnterior = history[history.length - 1];
        history.pop();
        localStorage.setItem('history_redo', JSON.stringify(history));
        addHistory();
        return stateAnterior;
      } else return state;

    case actionsTypes.SET_IS_DRAGGING:
      return { ...state, isDragging: action.isDragging };

    case actionsTypes.SET_IN_INPUT:
      return { ...state, inInput: action.inInput };

    case actionsTypes.SET_BLOCK:
      blocks = {
        ...state.slides[state.slideIndex].blocks,
        [action.blockKey]: action.block
      };
      slides = [
        ...state.slides.slice(0, state.slideIndex),
        {
          ...state.slides[state.slideIndex],
          blocks
        },
        ...state.slides.slice(state.slideIndex + 1)
      ];

      return { ...state, slides };

    case actionsTypes.SET_DEFAULT_BACKGROUND:
      return { ...state, defaultBackground: action.background };

    case actionsTypes.RESET_BLOCKS_SELECTED:
      return { ...state, blocksSelected: [] };

    default:
      return state;
  }
}
