import styled from 'styled-components';

export const Container = styled.div`
  min-height: 122px;
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 30px 0;
  padding: 0 30px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  .wrapper {
    display: flex;

    .card-icon {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: var(--primary-10);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      color: var(--primary);
    }

    .info-card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      h5.label-info-card {
        color: var(--primary-70);
        font-family: 'ArialRoundedBold', sans-serif;
        font-size: 16px;
      }

      span.value-label {
        color: #232323;
        font-weight: bold;
        font-family: 'ArialRoundedBold', sans-serif;
        margin-top: 5px;
        font-size: 16px;
      }
    }
  }

  .wrapper-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
