import React, { useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { FaArrowRight } from 'react-icons/fa';

import Input from '../../../components/Input';

import { Container, FormContainer, MessageError } from './../Login/styles';
import { Link } from 'react-router-dom';
import apiLmsManager from '../../../services/apiLmsManager';
import useLogo from '../../../hooks/useLogo';

function AlterarSenha(props) {
  const [messageUser, setMessageUser] = useState('');
  const [textBtnLogin, setTextBtnLogin] = useState('Alterar senha');
  const [resetToken, setResetToken] = useState('');
  const { logoUrl } = useLogo();

  async function handleSubmit(data) {
    try {
      if (data.new_password !== data.password_confirm)
        return setMessageUser('As senhas não correspondem!');

      setTextBtnLogin('Enviando...');
      const reset = await apiLmsManager.post('/reset-password/alterar-senha', {
        ...data,
        reset_token: resetToken
      });

      if (reset.status === 200) {
        alert('Senha alterada com sucesso!');
        props.history.push('/login');
      }
    } catch (e) {
      setMessageUser(
        'O link expirou, por favor solicite novamente a alteração de senha.'
      );
      setTextBtnLogin('Alterar senha');
    }
  }

  useEffect(() => {
    setResetToken(props.match.params.reset_token);
  }, [props.match.params.reset_token]);

  return (
    <Container>
      <FormContainer className="border-radius card-shadow card-form">
        <h2>
          <img src={logoUrl} alt="Logo App" width="120" />
        </h2>

        <MessageError>{messageUser}</MessageError>
        <Form onSubmit={handleSubmit}>
          <div className="input-block">
            <label className="text-primary">Nova Senha: </label>
            <Input
              name="new_password"
              type="password"
              className="form-control"
              required
            />

            <label className="text-primary">Confirmar Senha: </label>
            <Input
              name="password_confirm"
              type="password"
              className="form-control"
              required
            />
          </div>

          <br></br>

          <button type="submit" className="btn border-radius primary">
            {textBtnLogin}
            <FaArrowRight style={{ marginLeft: 3 }} />
          </button>
        </Form>
      </FormContainer>
      <Link to="/login">Voltar para tela de login</Link>
    </Container>
  );
}

export default AlterarSenha;
