import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiltroAcessos } from '../../../pages/Dashboard/styles';
import apiLmsConteudo from '../../../services/apiLmsConteudo';

import { Container } from './styles';
import SelectDay from './SelectDay';

function Filter({
  filter,
  setFilter,
  daysFilter,
  setDaysFilter,
  showDaysFilter = true
}) {
  const [cursos, setCursos] = useState([]);
  const [divisoes, setDivisoes] = useState([]);
  const [subdivisoes, setSubdivisoes] = useState([]);

  const [cursoSelected, setCursoSelected] = useState(
    localStorage.getItem('curso_filter')
      ? localStorage.getItem('curso_filter')
      : 'default'
  );
  const [divisaoSelected, setDivisaoSelected] = useState(
    localStorage.getItem('divisao_filter')
      ? localStorage.getItem('divisao_filter')
      : 'default'
  );
  const [subdivisaoSelected, setSubdivisaoSelected] = useState(
    localStorage.getItem('subdivisao_filter')
      ? localStorage.getItem('subdivisao_filter')
      : 'default'
  );

  const [optionsDay, setOptionsDay] = useState([
    { label: '07 dias', value: 7, selected: true },
    { label: '15 dias', value: 15, selected: false },
    { label: '21 dias', value: 21, selected: false }
  ]);

  const [cursosOptions, setCursosOptions] = useState([]);
  const [divisoesOptions, setDivisoesOptions] = useState([]);
  const [subdivisoesOptions, setSubdivisoesOptions] = useState([]);

  function onSelectDays(options, optionSelected) {
    setOptionsDay(options);
    setDaysFilter(optionSelected.value);
  }

  async function selectCurso(newOptions, option) {
    const cursoId = option.value;
    //setCursosOptions(newOptions);
    setCursoSelected(cursoId);
    localStorage.setItem('curso_filter', cursoId);

    const filter = {
      tipo: 'curso',
      id: cursoId,
      subtipo: 'divisao'
    };
    setFilter(filter);
    localStorage.setItem('filter', JSON.stringify(filter));

    setDivisoes([]);
    setSubdivisoes([]);

    setDivisaoSelected('default');
    localStorage.removeItem('divisao_filter');

    setSubdivisaoSelected('default');
    localStorage.removeItem('subdivisao_filter');

    try {
      const divisoes = (
        await apiLmsConteudo.get(`/divisao/${cursoId}/index`, {
          params: {
            exibirFiltro: true
          }
        })
      ).data;
      setDivisoes(divisoes);
      setSubdivisoes([]);
    } catch (e) {
      console.error(e);
    }
  }

  async function selectDivisao(newOptions, option) {
    const divisaoId = option.value;
    //setDivisoesOptions(newOptions);
    localStorage.setItem('divisao_filter', divisaoId);
    setDivisaoSelected(divisaoId);

    const filter = {
      tipo: 'divisao',
      id: divisaoId,
      subtipo: 'subdivisao'
    };
    setFilter(filter);
    localStorage.setItem('filter', JSON.stringify(filter));

    setSubdivisaoSelected('default');

    try {
      const subdivisoes = (
        await apiLmsConteudo.get(`/subdivisao/${divisaoId}/index`)
      ).data;
      setSubdivisoes(subdivisoes);

      localStorage.removeItem('subdivisao_filter');
    } catch (e) {
      console.log(e);
      localStorage.removeItem('subdivisao_filter');
    }
  }

  async function selectSubdivisao(newOptions, option) {
    const subdivisaoId = option.value;
    //setSubdivisoesOptions(newOptions);
    localStorage.setItem('subdivisao_filter', subdivisaoId);

    setSubdivisaoSelected(subdivisaoId);

    try {
      const filter = {
        tipo: 'subdivisao',
        id: subdivisaoId,
        subtipo: 'livro'
      };
      setFilter(filter);
      localStorage.setItem('filter', JSON.stringify(filter));
    } catch (e) {
      console.log(e);
    }
  }

  function limparCurso() {
    setCursoSelected('default');
    localStorage.removeItem('curso_filter');

    setDivisaoSelected('default');
    localStorage.removeItem('divisao_filter');

    setSubdivisaoSelected('default');
    localStorage.removeItem('subdivisao_filter');

    setSubdivisoes([]);
    setDivisoes([]);

    localStorage.removeItem('filter');
    setFilter(null);
  }

  function limparDivisao() {
    setDivisaoSelected('default');
    localStorage.removeItem('divisao_filter');

    setSubdivisaoSelected('default');
    localStorage.removeItem('subdivisao_filter');

    setSubdivisoes([]);

    const filter = {
      tipo: 'curso',
      id: cursoSelected,
      subtipo: 'divisao'
    };

    setFilter(filter);
  }

  function limparSubdivisao() {
    setSubdivisaoSelected('default');
    localStorage.removeItem('subdivisao_filter');

    const filter = {
      tipo: 'divisao',
      id: divisaoSelected,
      subtipo: 'subdivisao'
    };

    setFilter(filter);
  }

  useEffect(() => {
    async function getCursos() {
      const cursos = (await apiLmsConteudo.get('/curso/')).data;
      setCursos(cursos);
      if (cursoSelected) {
        try {
          const requestDivicoes = await apiLmsConteudo.get(
            `/divisao/${cursoSelected}/index`,
            {
              params: {
                exibirFiltro: true
              }
            }
          );
          const divisoes = requestDivicoes.data;
          setDivisoes(divisoes);
        } catch (e) {}
      }

      if (divisaoSelected) {
        try {
          const subdivisoes = (
            await apiLmsConteudo.get(`/subdivisao/${divisaoSelected}/index`)
          ).data;
          setSubdivisoes(subdivisoes);
        } catch (e) {}
      }
    }

    if (cursos.length === 0) getCursos();
  }, [cursos, subdivisaoSelected, cursoSelected, divisaoSelected]);

  useEffect(() => {
    if (cursos.length > 0) {
      let cursosOptions = [];
      if (cursoSelected !== 'default') {
        cursosOptions = [
          ...cursos.map(curso => ({
            label: curso.nome,
            value: curso.id,
            selected: curso.id === cursoSelected ? true : false
          }))
        ];
      } else {
        cursosOptions = [
          { label: 'Curso', value: '', selected: true },
          ...cursos.map(curso => ({
            label: curso.nome,
            value: curso.id,
            selected: curso.id === cursoSelected ? true : false
          }))
        ];
      }

      setCursosOptions(cursosOptions);
    }
  }, [cursos, cursoSelected]);

  useEffect(() => {
    if (divisoes.length > 0) {
      let divisoesOptions = [];
      if (divisaoSelected !== 'default') {
        divisoesOptions = [
          ...divisoes.map(divisao => ({
            label: divisao.nome,
            value: divisao.id,
            selected: divisao.id === divisaoSelected ? true : false
          }))
        ];
      } else {
        divisoesOptions = [
          { label: 'Divisão', value: '', selected: true },
          ...divisoes.map(divisao => ({
            label: divisao.nome,
            value: divisao.id,
            selected: divisao.id === divisaoSelected ? true : false
          }))
        ];
      }

      setDivisoesOptions(divisoesOptions);
    }
  }, [divisoes, divisaoSelected]);

  useEffect(() => {
    if (subdivisoes.length > 0) {
      let subdivisoesOptions = [];
      if (subdivisaoSelected !== 'default') {
        subdivisoesOptions = [
          ...subdivisoes.map(subdivisoes => ({
            label: subdivisoes.nome,
            value: subdivisoes.id,
            selected: subdivisoes.id === subdivisaoSelected ? true : false
          }))
        ];
      } else {
        subdivisoesOptions = [
          { label: 'Sub-divisão', value: 'default', selected: true },
          ...subdivisoes.map(subdivisoes => ({
            label: subdivisoes.nome,
            value: subdivisoes.id,
            selected: subdivisoes.id === subdivisaoSelected ? true : false
          }))
        ];
      }

      setSubdivisoesOptions(subdivisoesOptions);
    }
  }, [subdivisoes, subdivisaoSelected]);

  return (
    <Container>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ marginRight: 5 }}>
          {cursoSelected !== 'default' && (
            <button
              onClick={limparCurso}
              label="limpar filtro"
              className="btn-limpar-filtro"
            >
              <FaTimes />
            </button>
          )}
          <SelectDay options={cursosOptions} onSelect={selectCurso} />
        </div>

        {divisoes.length > 0 && (
          <div style={{ marginRight: 5 }}>
            {divisaoSelected !== 'default' && (
              <button
                onClick={limparDivisao}
                label="limpar filtro"
                className="btn-limpar-filtro"
              >
                <FaTimes />
              </button>
            )}
            <SelectDay options={divisoesOptions} onSelect={selectDivisao} />
          </div>
        )}

        {subdivisoes.length > 0 && (
          <div style={{ marginRight: 5 }}>
            {subdivisaoSelected !== 'default' && (
              <button
                onClick={limparSubdivisao}
                label="limpar filtro"
                className="btn-limpar-filtro"
              >
                <FaTimes />
              </button>
            )}
            <SelectDay
              options={subdivisoesOptions}
              onSelect={selectSubdivisao}
            />
          </div>
        )}
      </div>

      {showDaysFilter && (
        <FiltroAcessos>
          <SelectDay
            options={optionsDay}
            onSelect={onSelectDays}
            style={{ marginTop: 10 }}
          />
        </FiltroAcessos>
      )}
    </Container>
  );
}

export default Filter;
