import axios from 'axios';

let refreshTokenPromise = null;

const apiLmsManager = axios.create({
  baseURL: process.env.REACT_APP_LMS_MANAGER_API_URL
});

apiLmsManager.interceptors.request.use(async config => {
  config.headers['Content-type'] = `application/json`;
  if (
    config.url !== '/login_check' &&
    config.url !== '/reset-password' &&
    config.url !== '/reset-password/alterar-senha' &&
    config.url !== '/empresa/logo'
  ) {
    const token = localStorage.getItem('@RockfellerToken');
    token
      ? (config.headers['Authorization'] = `Bearer ${token}`)
      : (window.location.href = '/login');
  }

  return config;
});

apiLmsManager.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response.config.url === '/login_check') {
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 &&
      error.response.config.url === '/token/refresh'
    ) {
      localStorage.removeItem('@RockfellerToken');
      localStorage.removeItem('@RockfellerRefreshToken');
      localStorage.removeItem('@RockfellerType');
      window.location.href = '/login';
    } else if (
      error.response.status === 401 &&
      localStorage.getItem('@RockfellerRefreshToken') &&
      error.response.config.url !== '/login_check'
    ) {
      // Executa a atualização do token somente se não houver uma em andamento
      if (!refreshTokenPromise) {
        refreshTokenPromise = new Promise(async (resolve, reject) => {
          try {
            const refreshTokenResponse = await apiLmsManager.post(
              '/token/refresh',
              {
                refresh_token: localStorage.getItem('@RockfellerRefreshToken')
              }
            );

            localStorage.setItem(
              '@RockfellerToken',
              refreshTokenResponse.data.token
            );
            localStorage.setItem(
              '@RockfellerRefreshToken',
              refreshTokenResponse.data.refresh_token
            );

            resolve(refreshTokenResponse);
          } catch (refreshError) {
            reject(refreshError);
          } finally {
            refreshTokenPromise = null; // Limpa a promessa depois que terminar
          }
        });
      }

      // Aguarda a resolução da promessa de atualização do token
      await refreshTokenPromise;

      // Repete a solicitação que falhou depois de atualizar o token
      return apiLmsManager(error.config);
    } else if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('@RockfellerToken');
      localStorage.removeItem('LOGO');
      //window.location.href = '/login';
      // Repete a solicitação que falhou
      return apiLmsManager(error.config);
    }
    return Promise.reject(error);
  }
);

export default apiLmsManager;
