import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  margin: 20px auto 0 auto;
  width: 100%;
  .btn-info {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #4a4a4a;
    font-weight: 700;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
  }
`;

export const HeaderInfo = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: -30px;
  border-bottom: 2px solid #979797;
  padding-bottom: 20px;
`;

export const UnitContainer = styled.div`
  display: flex;
  background-color: #fff0bf;
  height: 140px;
  width: 100%;
  padding-top: 16px;
  padding-left: 50px;
  padding-right: 30px;
  color: #4a4a4a;
  flex-direction: column;
  margin-top: 10px;
  h2 {
    font-weight: 400;
  }
`;

export const HeaderUnitContainer = styled.header`
  display: flex;
  justify-content: space-between;
  button {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #4a4a4a;
    font-weight: 700;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
  }
`;

export const InfoAulaContainer = styled.div`
  display: flex;
  width: 70%;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: space-between;
`;

export const InfoAula = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  .dado {
    margin-top: 6px;
    font-size: 21px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UnitInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 34px;
  span {
    font-size: 15px;
  }
`;

export const SubnivelContainer = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
  span {
    font-size: 21px;
  }
`;

export const AulasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  button {
    margin-top: 12px;
    margin-right: 25px;
  }
`;

export const ProgressUnitContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  span {
    font-size: 13px;
  }
`;

export const AulaStatsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 108px;
  margin-bottom: 13px;
  padding-left: 50px;
  padding-right: 30px;
`;
