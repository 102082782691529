import { combineReducers } from 'redux';
import SlideReducer from './SlideReducer';
import TextReducer from './TextReducer';
import ShowReducer from './ShowReducer';
import BreadcrumbReducer from './BreadcrumbReducer';
import QuizReducer from './QuizReducer';
import AulaReducer from './AulaReducer';
import ConfigReducer from './ConfigReducer';
import PracticeReducer from './PracticeReducer';
import PlacarReducer from './PlacarReducer';
import TotemReducer from './TotemReducer';
import GaleriaImagemReducer from './GaleriaImagemReducer';
import AbaReducer from './AbaReducer';
import CardReducer from './CardReducer';
import ExecucaoReducer from './ExecucaoReducer';
import CardChallengeReducer from './CardChallengeReducer';
import TicTacToeReducer from './TicTacToeReducer';
import FoodGameReducer from './FoodGameReducer';
import ParkingGameReducer from './ParkingGameReducer';
import MemoryGameReducer from './MemoryGameReducer';
import SnookerGameReducer from './SnookerGameReducer';
import GlobeGameReducer from './GlobeGameReducer';
import PrepositionGameReducer from './PrepositionGameReducer';
import SequenceImagesGameReducer from './SequenceImagesGameReducer';
import WorkplaceGameReducer from './WorkplaceGameReducer';
import SheepGameReducer from './SheepGameReducer';
import ClickableReducer from './ClickableReducer';
import DragnDropReducer from './DragnDropReducer';
import SelectorReducer from './SelectorReducer';
import MatchReducer from './MatchReducer';
import DiceReducer from './DiceReducer';
import OrdenaQuizReducer from './OrdenaQuizReducer';

export default combineReducers({
  SlideReducer,
  TextReducer,
  ShowReducer,
  BreadcrumbReducer,
  QuizReducer,
  AulaReducer,
  ConfigReducer,
  PracticeReducer,
  PlacarReducer,
  TotemReducer,
  GaleriaImagemReducer,
  AbaReducer,
  CardReducer,
  ExecucaoReducer,
  CardChallengeReducer,
  TicTacToeReducer,
  FoodGameReducer,
  ParkingGameReducer,
  MemoryGameReducer,
  SnookerGameReducer,
  GlobeGameReducer,
  PrepositionGameReducer,
  SequenceImagesGameReducer,
  WorkplaceGameReducer,
  SheepGameReducer,
  ClickableReducer,
  DragnDropReducer,
  SelectorReducer,
  MatchReducer,
  DiceReducer,
  OrdenaQuizReducer
});
