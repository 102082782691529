import styled from 'styled-components';

export const Container = styled.div``;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-top: 14px;
  font-family: 'Open Sans';
  margin-bottom: 50px;

  th {
    color: var(--primary) !important;
    font-size: 13px;
    text-align: center;
    font-weight: 400 !important;
    padding: 5px;
  }

  th,
  td {
    text-align: left;
    padding: 6px 5px;
    font-weight: 600;
    font-size: 14px;
    color: #4a4a4a;
  }

  tbody {
    border-collapse: separate;
    width: 100%;
    border-radius: 8px;
  }
  tr {
    background: #fcfaf0;
    cursor: default;
  }
  tr:nth-child(even) {
    background-color: #fff0bf;
  }
`;
