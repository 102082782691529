import React from 'react';
import { Icon } from '@iconify/react';

import { Container } from './styles';

function CardInfo({
  icon = <Icon icon={'icons8:student'} style={{ transform: 'scaleX(-1)' }} />,
  title = '',
  value = '',
  moreInfo = <></>,
  style = {}
}) {
  return (
    <Container className="border-rounded" style={style}>
      <div className="wrapper-column">
        <div className="wrapper">
          <div className="card-icon">{icon}</div>

          <section className="info-card">
            <h5 className="label-info-card">{title}</h5>
            <span className="value-label">{value}</span>
          </section>
        </div>
        {moreInfo}
      </div>
    </Container>
  );
}

export default CardInfo;
