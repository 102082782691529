import { useEffect, useState } from 'react';
import apiLmsManager from '../services/apiLmsManager';

export default function useLogo() {
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    const logo = localStorage.getItem('LOGO');
    if (!logo) {
      apiLmsManager
        .get('/empresa/logo')
        .then(response => {
          if (response.data.logo_lms) {
            setLogoUrl(response.data.logo_lms);
            localStorage.setItem('LOGO', response.data.logo_lms);
          } else {
            setLogoUrl(
              'https://rockfeller.s3.sa-east-1.amazonaws.com/assets/images/logo-lms.png'
            );
          }
        })
        .catch(e => {
          setLogoUrl(
            'https://rockfeller.s3.sa-east-1.amazonaws.com/assets/images/logo-lms.png'
          );
        });
    } else {
      setLogoUrl(logo);
    }
  }, []);

  return { logoUrl };
}
