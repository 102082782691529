import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';

function AlunosAtivosLoader() {
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const containerDash = document.querySelector('.item-access');
    if (!containerDash) return;

    setContainerWidth(containerDash.clientWidth);
    window.onresize = () => {
      setContainerWidth(containerDash.clientWidth);
    };
  }, []);

  return (
    <ContentLoader
      speed={1}
      width={containerWidth}
      height={310}
      backgroundColor="#eee"
      foregroundColor="#ddd"
    >
      <rect x="0" y="30" rx="25" ry="25" width={containerWidth} height="250" />
    </ContentLoader>
  );
}

export default AlunosAtivosLoader;
