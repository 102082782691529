import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  totems: {},
  change: false,
  resetTotems: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.ADD_TOTEM:
      return {
        ...state,
        totems: {
          ...state.totems,
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        change: !state.change
      };

    case actionsTypes.SET_TOTEM:
      return {
        ...state,
        totems: {
          ['totem_' + action.totem.blockIndex]: action.totem
        },
        change: !state.change
      };

    case actionsTypes.RESET_TOTEMS:
      return { ...state, resetTotems: action.resetTotems };

    case actionsTypes.SET_TOTEM_POSITION_STUDENT:
      return { ...state, totemPositionStudent: action.totem };

    case actionsTypes.SET_TOTEM_POSITION_TEACHER:
      return { ...state, totemPositionTeacher: action.totem };

    case actionsTypes.SET_UPDATE_TOTEM:
      return { ...state, updateTotem: action.totem };

    default:
      return state;
  }
}
