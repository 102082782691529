import React, { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import Pagination from 'react-js-pagination';
import AppLayout from '../../../layouts/App';
import apiLmsProgresso from '../../../services/apiLmsProgresso';

import { Container, Table } from './styles';

function ProgressoAlunos(props) {
  const [alunos, setAlunos] = useState([]);
  const [aula, setAula] = useState({});
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemNumberPerPage: '',
    totalItems: ''
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function getAlunosAula(idAula, page) {
      const data = (
        await apiLmsProgresso.get(
          `/relatorio/aula/${idAula}/alunos?page=${page}`
        )
      ).data;
      setAula(data.aula);
      setAlunos(data.alunos);
      setPagination(data.pagination);
    }

    const idAula = props.match.params.idAula;
    if (idAula) {
      getAlunosAula(idAula, page);
    }
  }, [props.match.params.idAula, page]);

  function changePage(page) {
    setPage(page);
  }

  return (
    <AppLayout
      title={
        <>
          {'Alunos '}
          <span>{'Aula ' + aula?.nome}</span>
        </>
      }
      {...props}
    >
      <Container>
        <Table>
          <thead>
            <th>Nome do aluno</th>
            <th>Progresso</th>
            <th>Aproveitamento</th>
            <th></th>
          </thead>

          <tbody className="box-shadow">
            {alunos?.map((aluno, index) => (
              <tr key={aluno._id}>
                <td>{aluno.nome}</td>
                <td>
                  {!isNaN(aluno.progresso) ? Math.round(aluno.progresso) : 0}
                </td>
                <td>
                  {!isNaN(aluno.aproveitamento)
                    ? Math.round(aluno.aproveitamento)
                    : 0}
                </td>
                <td
                  onClick={() =>
                    props.history.push(`/perfil-aluno/${aluno.id}`)
                  }
                >
                  Ver perfil <FaAngleRight />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {parseInt(pagination.totalItems) >
          parseInt(pagination.itemNumberPerPage) && (
          <Pagination
            activePage={pagination.currentPage}
            itemsCountPerPage={parseInt(pagination.itemNumberPerPage)}
            totalItemsCount={parseInt(pagination.totalItems)}
            pageRangeDisplayed={5}
            onChange={changePage}
            innerClass="pagination"
            activeClass="primary"
            itemClass="btn btn-border-radius-right btn-border-radius-left"
            hideNavigation
          />
        )}
      </Container>
    </AppLayout>
  );
}

export default ProgressoAlunos;
