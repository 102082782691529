import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root{
    --primary: #0059A3;
    --secondary: #ffcc29;
    --danger: #d32121;
    --success: #7ed321;
    --primary-light: #305fb8;
    --primary-10: #0059A310;
    --primary-70: #0059A370;
    --orange: #ff9a00;
  }
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
  }


  body {
    font: 14px 'Open Sans', sans-serif;
    color: #333;
    -webkit-font-smoothing: antialiased !important;
    background: #f4f4f2;
  }

  ul {
    list-style: none;
  }

  .container{
    width: 80%;
    margin: 0 auto;
  }
  @media only screen and (max-width: 576px){
    .container{
      max-width: 100%;
    }
  }
  @media only screen and (min-width: 576px){
    .container{
      max-width: 546px;
    }
  }

  @media only screen and (min-width: 768px){
    .container{
      max-width: 720px;
    }
  }

  @media only screen and (min-width: 992px){
    .container{
      max-width: 960px;
    }
  }

  @media only screen and (min-width: 1200px){
    .container{
      max-width: 1140px;
    }
  }

  .btn {
    border: none;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 12px 24px;
    transition: 0.2s;
    border: 1px solid #D9DCE9;
    background-color: transparent;
    cursor: pointer;
    background-color: #fff;
    font-family: 'ArialRoundedMt';
  }

  .resposta-formatada {
    b {
      color: var(--danger);
    }
  }

  .center-flex{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .border-rounded {
    border-radius: 25px;
  }

  .btn-border-radius-left, .border-radius-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .btn-border-radius-right, .border-radius-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .border-radius-top-right {
    border-top-right-radius: 6px;
  }

  .border-radius-top-left {
    border-top-left-radius: 6px;
  }

  .border-radius-bottom-left {
    border-bottom-left-radius: 6px;
  }

  .border-radius-bottom-right {
    border-bottom-right-radius: 6px;
  }


  .border-radius {
    border-radius: 6px;
  }

  .no-border {
    border: none;
  }

  .transition{
    transition: all .4s;
  }

  .card {
    border-radius: 8px;
    background: #d8d8d8;
    padding-left: 15px;
    padding-right: 15px;
    opacity: 1;
    display: flex!important;
    flex-direction: column;
    justify-content: center;
  }

  .card-speaking{
    border-radius: 8px;
    display: flex;
    align-items: center;
    background: #d8d8d8;
    padding-left: 50px;
    display: flex !important;
    div, span{
      width: 100%;
    }
  }

  .oval{
    border-radius: 50%;
    background: #fff;
    color: #4a4a4a;
    display: flex!important;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: center;
    text-transform: uppercase;
    padding: 0px 10px !important;
    //border: 1px solid #929292;
    box-shadow: 0 2px 4px rgba(0,0,0,0.3);
  }

  .alert{
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    p {
      margin-top: 0;
      margin-bottom: 1rem;
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    .h4, h4 {
      font-size: 1.5rem;
      margin-bottom: .5rem;
    }
  }

  .alert-success{
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  .alert-primary {
    color: var(--primary);
    background-color: #cce5ff;
    border-color: #b8daff;
  }


  .alert-heading {
    color: inherit;
  }

  .btn-default {
    background-color: white;
    color: var(--primary);
    border: none;
  }

  .form-control {
    display: block;
    width: 100%;
    /*height: calc(1.5em + .75rem + 2px);*/
    height: 50px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D9DCE9;
    /*border-radius: .25rem;*/
    border-radius: 8px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .select {
    min-height: 50px;
  }

  .primary {
    background: var(--primary) ;
    color: #fff;
    a{
      color: #fff!important;
    }
  }

  .success {
    background: var(--success);
    color: #fff;
  }

  .secondary {
    background: var(--secondary) ;
    color: #4a4a4a;
  }

  .danger{
    background: var(--danger);
    color: #fff;
  }

  .content

  .ultralightblue{
    background: #93B7FA;
  }

  .lightblue{
    background: #CFE0FF;
  }

  .darkblue{
    background: #537AC5;
  }

  .text-primary{
    color: var(--primary);
  }

  .text-success{
    color: var(--success);
  }

  .text-secondary{
    color: var(--secondary);
  }
  
  .text-danger{
    color: var(--danger);
  }
  
  .card-shadow{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .react-draggable {
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
  }
  label {
    display: inline-block;
    margin-bottom: .5rem;
  }
  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: 10px;
  }

  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .mb-3, .my-3 {
    margin-bottom: 1rem!important;
  }

  @media (min-width: 768px) {
    .col-md-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-md-3 {
        -ms-flex: 0 0 33.333%;
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }
  }

  .paleta-conteudo-ultralight {
    background: #93B7FA;
  }

  .paleta-conteudo-light {
    background: #CFE0FF;
  }

  .paleta-conteudo-dark {
    background: #6A8AC7;
  }

  .badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }

  .pagination{
    display: flex;
    margin-top: 20px;
  }

  .list-table {
    width: 100%;
    border-spacing: 0;
    background-color: transparent;
  th {
    color: var(--primary);
    font-family: 'ArialRoundedMt', sans-serif;
  }

  th,
  td {
    text-align: left;
    padding: 10px 30px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
  }
    button {
      text-transform: uppercase;
      border: none;
      background: transparent;
      margin: 0 5px;
      cursor: pointer;
      font-weight: bold;
      opacity: 0.7;
      :hover {
        opacity: 1;
      }
    }
    
    tbody {
      border-collapse: separate;
      width: 100%;
      border: 0px solid #ddd;
      border-radius: 25px;
    }
    tr {
    background: var(--primary-10);
    cursor: default;
    }
    tr:nth-child(even) {
      background-color: white;
    }

    thead {
      tr{
        background-color: transparent;
      }
    }
  }
  
  .d-flex {
    display: flex;
  }
  
  .input-error {
      border: 1px solid red;
  }

`;
