import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  aulaId: null,
  livroId: null,
  path: '',
  aula: {},
  changeCronometro: null,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_AULA_ID:
      return { ...state, aulaId: action.aulaId };

    case actionsTypes.SET_LIVRO_ID:
      return { ...state, livroId: action.livroId };

    case actionsTypes.SET_AULA_PATH:
      return { ...state, path: action.path };

    case actionsTypes.SET_AULA:
      return { ...state, aula: action.aula };

    case actionsTypes.SET_IS_MUTE_ALL:
      return { ...state, isMuteAll: action.isMuteAll };

    case actionsTypes.SET_IS_PREVIOUS_AUDIO_STATUS:
      return {
        ...state,
        isSetPreviousAudioStatus: action.isSetPreviousAudioStatus
      };
    case actionsTypes.CHANGE_CRONOMETRO:
      return { ...state, changeCronometro: !state.changeCronometro };

    default:
      return state;
  }
}
