import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import GlobalStyle from './styles/global';
import Router from './routes';

import store from './store';
import themes from './styles/themes';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={themes.default}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Router />
        </LocalizationProvider>
        <GlobalStyle />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
