import actionsTypes from '../constants/ActionsTypes';

const consultaExternaDefault = {
  id: null,
  numero: 1,
  endpointConsulta: '',
  campo: '',
  valor: '',
  login: '',
  senha: '',
  token: ''
};

const INITIAL_STATE = {
  corPrimaria: '#0040B9',
  corTextoPrimaria: '#fff',
  corSecundaria: '#FFCC29',
  corTextoSecundaria: '#333',
  paletaCorConteudo: 'default',
  aulaConfigsConsultasExternas: [consultaExternaDefault]
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_LIVRO_CORES:
      return {
        ...state,
        corPrimaria: action.livro.cor_primaria ?? state.cor_primaria,
        corTextoPrimaria:
          action.livro.cor_texto_primaria ?? state.cor_texto_primaria,
        corSecundaria: action.livro.cor_secundaria ?? state.cor_secundaria,
        corTextoSecundaria:
          action.livro.cor_texto_secundaria ?? state.cor_texto_secundaria,
        paletaCorConteudo:
          action.livro.paleta_cor_conteudo ?? state.paleta_cor_conteudo
      };
    default:
      return state;
  }
}
