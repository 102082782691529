import React, { useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { BtnExpand, Container, MenuUser, UserBall } from './styles';
import useOutsideClick from '../../hooks/useOutsideClick';

function UserMenu({ name = '', username = '' }) {
  const [showMenuUser, setShowMenuUser] = useState(false);
  const menuRef = useRef();
  useOutsideClick(menuRef, () => setShowMenuUser(false));

  function logout() {
    localStorage.removeItem('@RockfellerToken');
    localStorage.removeItem('@User');
    localStorage.removeItem('LOGO');
    window.location.href = '/login';
  }

  return (
    <Container>
      <UserBall>
        <Icon icon="ooui:user-active" fontSize={32} style={{ marginTop: 6 }} />
      </UserBall>
      <div className="user-info-container">
        <h5>{name.split(' ')[0]}</h5>
        <span>Login: {username}</span>
      </div>
      <BtnExpand onClick={() => setShowMenuUser(true)}>
        <Icon icon="ooui:expand" fontSize={20} />
      </BtnExpand>
      <MenuUser ref={menuRef} show={showMenuUser}>
        <li onClick={logout}>
          <Icon
            icon="ph:sign-out-bold"
            fontSize={20}
            style={{ marginRight: 5 }}
          />
          Sair
        </li>
      </MenuUser>
    </Container>
  );
}

export default UserMenu;
