import React, { useEffect, useRef, useState } from 'react';
import { Container, ContainerOptions, Option, SelectedOption } from './styles';
import { Icon } from '@iconify/react';
import useOutsideClick from '../../../../hooks/useOutsideClick';

/**
 * @typedef OptionType
 * @property {string} label
 * @property {number} value
 * @property {boolean} selected
 */

/**
 * @typedef SelectDayProps
 * @property {Array<OptionType>} options
 * @property {(newOptions: Array<OptionType>, option: OptionType) => void} onSelect
 * @property {?import('styled-components').CSSProperties} style
 */

/**
 *
 * @param {SelectDayProps} props
 * @returns
 */
function SelectDay({ options = [], onSelect, style = {} }) {
  const [selected, setSelected] = useState(
    options.length > 0 ? options[0] : null
  );
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef();
  useOutsideClick(containerRef, () => setShowOptions(false));

  function onSelectDay(option, indexOption) {
    const newOptions = [...options].map((option, index) => {
      return {
        ...option,
        selected: false
      };
    });

    newOptions[indexOption].selected = true;

    onSelect(newOptions, option);

    setShowOptions(false);
  }

  useEffect(() => {
    options.forEach(option => {
      if (option.selected) {
        setSelected(option);
      }
    });
  }, [options]);

  return (
    <Container ref={containerRef} style={style}>
      <SelectedOption onClick={() => setShowOptions(value => !value)}>
        {selected?.label} <Icon icon="ooui:expand" style={{ marginLeft: 5 }} />
      </SelectedOption>
      <ContainerOptions show={showOptions}>
        {options.map((option, index) => (
          <Option
            key={option.label}
            onClick={() => onSelectDay(option, index)}
            selected={option.selected}
          >
            {option.label}
          </Option>
        ))}
      </ContainerOptions>
    </Container>
  );
}

export default SelectDay;
