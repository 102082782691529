import React from 'react';

import { Container } from './styles';
import { Table } from '../../../pages/Dashboard/styles';

/**
 * @typedef RankingAlunoType
 * @property {string} nome
 * @property {string} id uuid
 * @property {string} escola nome da escola
 * @property {number} minutos
 * @property {number} horas
 * @property {string} modalidade
 * @property {string} tempo tempo total em minutos
 */

/**
 * @typedef RankingAlunoProps
 * @property {Array<RankingAlunoType>} rankingAlunos
 * @property {any} history
 */
/**
 *
 * @param {RankingAlunoProps} param0
 * @returns
 */
function RankingAluno({ rankingAlunos = [], history }) {
  function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }

  return (
    <Container className="border-rounded">
      <div className="podium">
        {rankingAlunos.map((ranking, index) =>
          index < 3 ? (
            <div className="item-ranking" key={ranking.id}>
              <div className="medal">
                {index < 3 && (
                  <img
                    alt="Medalha"
                    src={require(`./assets/medalha_${index + 1}.svg`)}
                  />
                )}
              </div>
              <div className="escrita">
                <h5 title={ranking.nome}>
                  {ranking.nome.split(' ')[0] +
                    ' ' +
                    ranking.nome.split(' ')[1]}
                </h5>
                <span title={ranking.escola}>{ranking.escola}</span>
                <span
                  className="time"
                  title={
                    ranking.horas + ' Horas e ' + ranking.minutos + ' Minutos'
                  }
                >
                  {ranking.horas > 0 ? ranking.horas + 'H' : ''}
                  {ranking.minutos}Min
                </span>
                <span>{ranking.modalidade}</span>
              </div>
            </div>
          ) : (
            <React.Fragment key={ranking.id}></React.Fragment>
          )
        )}
      </div>

      <Table>
        <thead>
          <tr>
            <th></th>
            <th>Aluno</th>
            <th>Modalidade</th>
            <th>Horas</th>
          </tr>
        </thead>

        <tbody>
          {rankingAlunos.map((aluno, index) =>
            index > 2 ? (
              <tr key={aluno._id}>
                <td style={{ fontWeight: 400 }}>{index + 1}</td>
                <td>
                  {aluno.nome.split(' ')[0] + ' ' + aluno.nome.split(' ')[1]}
                </td>
                <td>{aluno.modalidade}</td>
                <td>
                  {minTwoDigits(aluno.horas)}h {minTwoDigits(aluno.minutos)}
                  min
                </td>
              </tr>
            ) : (
              <React.Fragment key={aluno.id}></React.Fragment>
            )
          )}
        </tbody>
      </Table>

      <button
        className="btn-ranking border-radius-bottom-left border-radius-bottom-right"
        onClick={() => history.push('/ranking-alunos')}
      >
        Ranking Completo
      </button>
    </Container>
  );
}

export default RankingAluno;
