import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100%;
  height: 402px;
  padding-top: 30px;
  padding-bottom: 45px;
  position: relative;
  max-height: 402px;
  overflow: hidden;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  .podium {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .medal {
      img {
        width: 55px;
        height: 55px;
      }
    }
    .escrita {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      h5 {
        font-size: 14px;
        color: #232323;
        overflow: hidden;
        height: 20px;
        text-overflow: clip;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        overflow: hidden;
        height: 20px;
        display: flex;
      }
      span.time {
        color: #718ebf;
        font-size: 15px;
      }
    }
  }

  .item-ranking {
    display: flex;
    align-items: center;
    width: 150px;
  }

  .btn-ranking {
    width: 100%;
    height: 45px;
    position: absolute;
    bottom: 0;
    border: none;
    background-color: var(--primary);
    color: white;
    font-family: 'ArialRoundedMt';
    font-size: 16px;
    left: 0;
    cursor: pointer;
  }
`;
