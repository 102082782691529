import styled, { keyframes } from 'styled-components';

const SlideDownAnimation = keyframes`
  from {
    transform: translateY(-20px);
  }
  to{ 
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--primary);
  font-family: 'ArialRoundedMt', sans-serif;
  position: relative;
  .user-info-container {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    h5 {
      font-size: 20px;
    }
    span {
      font-family: 'Open Sans';
      color: #333;
      font-weight: 400;
      font-size: 10px;
    }
  }
`;

export const UserBall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid var(--primary);
  overflow: hidden;
`;

export const BtnExpand = styled.button`
  border: none;
  background-color: none;
  color: var(--primary);
  cursor: pointer;
`;

export const MenuUser = styled.ul`
  position: absolute;
  background-color: #fff;
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  right: -20px;
  margin-top: 120px;
  width: 200px;
  z-index: 20;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary);
  animation: ${SlideDownAnimation} 400ms;
  li {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-family: 'ArialRoundedMt', sans-serif;
    cursor: pointer;
    width: 100%;
    &:hover {
      background-color: var(--primary-10);
    }
    & + & {
      border-top: 1px solid var(--primary);
    }
  }
`;
