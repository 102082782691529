import axios from 'axios';
import apiLmsManager from './apiLmsManager';

const apiLmsConteudo = axios.create({
  baseURL: process.env.REACT_APP_LMS_CONTEUDO_API_URL
});

apiLmsConteudo.interceptors.request.use(async config => {
  config.headers['Content-type'] = `application/json`;

  if (config.url !== '/login_check') {
    const token = localStorage.getItem('@RockfellerToken');
    token
      ? (config.headers['Authorization'] = `Bearer ${token}`)
      : (window.location.href = '/login');
  }

  return config;
});

apiLmsConteudo.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error.response === undefined) return Promise.reject(error);
    if (
      error.response.status === 401 &&
      localStorage.getItem('@RockfellerRefreshToken')
    ) {
      //chama o get user do lms manager para fazer refresh token
      await apiLmsManager.get('/user');

      const requestConfig = error.config;

      return apiLmsConteudo(requestConfig);
    } else if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('@RockfellerToken');
      window.location.href = '/login';
      const requestConfig = error.config;
      return apiLmsConteudo(requestConfig);
    }
    return Promise.reject(error);
  }
);

export default apiLmsConteudo;
