import React, { useEffect, useState } from 'react';
import { FaTimes, FaFolder, FaSearch } from 'react-icons/fa';
import {
  Body,
  Image,
  Categoria,
  Video,
  Audio,
  AssetItem,
  AssetName,
  SearchWrapper
} from './styles';

import { Container, Modal, Header, Footer } from '../../Modal/styles';
import apiLmsConteudo from '../../../services/apiLmsConteudo';
import { BeatLoader } from 'react-spinners';
import CreatableSelect from 'react-select/creatable';
import { useSelector, useDispatch } from 'react-redux';
import actionsGaleria from '../../../actions/Galeria';

function GaleriaImagens({ show, onImageSelect, type = null }) {
  const dispatch = useDispatch();
  const [assets, setAssets] = useState([]);
  const [assetSelectedIndex, setAssetSelectedIndex] = useState(-1);
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');

  const [categorias, setCategorias] = useState([]);

  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);

  const [categoriasSelect, setCategoriasSelect] = useState([]);

  const [showRecents, setShowRecents] = useState(false);

  const aulaId = useSelector(state => state.AulaReducer.aulaId);

  const galleryType = {
    image: 'Imagens',
    video: 'Videos',
    audio: 'Áudios'
  };

  function closeModal() {
    setAssetSelectedIndex(-1);
    setImageUrl('');
    dispatch(actionsGaleria.setGaleriaImagem(false, () => {}));
  }

  useEffect(() => {
    setLoading(true);
    async function getCategorias() {
      const categorias = (await apiLmsConteudo.get('/categoria_asset/list'))
        .data;
      setCategorias(categorias);
      setLoading(false);
    }
    getCategorias();
  }, []);

  useEffect(() => {
    async function getAssets() {
      setLoading(true);
      apiLmsConteudo
        .get(
          `/assets/?search=${search}${
            categoriaSelecionada?.id
              ? `&categoria_id=${categoriaSelecionada.id}`
              : ''
          }${type ? `&tipo=${type}` : ''}`
        )
        .then(response => {
          const assets = response.data;
          setAssets(assets);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (categoriaSelecionada) getAssets();
    if (search !== '') getAssets();
    if (showRecents) getAssets();
  }, [categoriaSelecionada, search, type, showRecents]);

  function handleClickImage(imageUrl, index) {
    setAssetSelectedIndex(index);
    setImageUrl(imageUrl.split(' ').join('%20'));
  }

  function onSubmitSearch(e) {
    e.preventDefault();

    const element = document.getElementById('pesquisar-assets');
    if (element) {
      const search = element.value;
      setShowRecents(false);
      setSearch(search);
    }
  }

  async function handleCreateCategoria(newValue, actionMeta) {
    if (actionMeta.action === 'create-option') {
      setLoading(true);
      try {
        const data = {
          nome: newValue.value
        };
        const categoria = (
          await apiLmsConteudo.post('/categoria_imagem', JSON.stringify(data))
        ).data;
        setCategoriaSelecionada(categoria);
      } catch (e) {}
      setLoading(false);
    } else if (actionMeta.action === 'select-option') {
      const image = assets[assetSelectedIndex];
      const data = {
        key: image.Key,
        categoria: newValue.nome
      };
      await apiLmsConteudo.post('/move-imagem', JSON.stringify(data));
      setAssetSelectedIndex(-1);
      newValue
        ? setCategoriaSelecionada({
            id: newValue.id,
            nome: newValue.nome
          })
        : setCategoriaSelecionada({});
    }
  }

  async function handleClickInserir() {
    const data = (
      await apiLmsConteudo.post(
        '/copia-imagem-aula',
        JSON.stringify({
          imageUrl,
          aulaId
        })
      )
    ).data;
    onImageSelect(data);
    closeModal();
  }

  useEffect(() => {
    setCategoriasSelect(
      categorias.map(categoria => ({
        ...categoria,
        value: categoria.nome,
        label: categoria.nome
      }))
    );
  }, [categorias]);

  return (
    <Container show={show}>
      <Modal style={{ width: '60%' }}>
        <Header>
          <SearchWrapper>
            <h1>Galeria de {!type ? 'Assets' : galleryType[type]} </h1>
            <form onSubmit={onSubmitSearch}>
              <input
                type="search"
                placeholder="Pesquisar por nome"
                className="form-control"
                id="pesquisar-assets"
              />
              <button className="btn primary border-radius" type="submit">
                <FaSearch />
              </button>
            </form>
          </SearchWrapper>

          <span className="closeButton" onClick={closeModal}>
            <FaTimes />
          </span>
        </Header>

        <Body>
          {loading && <BeatLoader color={'rgb(0, 64, 185)'} />}
          {(categoriaSelecionada || search || showRecents) &&
            assets.map((asset, index) => {
              if (asset.tipo === 'image') {
                return (
                  <AssetItem
                    key={index}
                    onClick={() => handleClickImage(asset.url, index)}
                    selected={index === assetSelectedIndex}
                  >
                    <Image src={asset.url} />
                    <AssetName>{asset.nome}</AssetName>
                  </AssetItem>
                );
              }

              if (asset.tipo === 'video') {
                return (
                  <AssetItem
                    key={index}
                    onClick={() => handleClickImage(asset.url, index)}
                    selected={index === assetSelectedIndex}
                  >
                    <Video src={asset.url} />
                    <AssetName>{asset.nome}</AssetName>
                  </AssetItem>
                );
              }

              if (asset.tipo === 'audio') {
                return (
                  <AssetItem
                    key={index}
                    onClick={() => handleClickImage(asset.url, index)}
                    selected={index === assetSelectedIndex}
                  >
                    <Audio src={asset.url} controls />
                    <AssetName>{asset.nome}</AssetName>
                  </AssetItem>
                );
              }

              return <React.Fragment key={index} />;
            })}

          {!categoriaSelecionada && !showRecents && !search && (
            <Categoria
              key="recents"
              className="border-radius"
              onClick={() => setShowRecents(true)}
            >
              <FaFolder className="text-primary" />
              <span className="text-primary">Recentes</span>
            </Categoria>
          )}

          {!categoriaSelecionada &&
            !search &&
            !showRecents &&
            categorias.map(categoria => (
              <Categoria
                key={categoria.id}
                className="border-radius"
                onClick={() => setCategoriaSelecionada(categoria)}
              >
                <FaFolder />
                <span>{categoria.nome}</span>
              </Categoria>
            ))}
        </Body>

        <Footer>
          {(categoriaSelecionada || showRecents) && (
            <button
              className="btn primary border-radius"
              onClick={() => {
                setCategoriaSelecionada(null);
                setAssetSelectedIndex(-1);
                setSearch('');
                setShowRecents(false);
              }}
              style={{
                position: 'absolute',
                left: 5,
                bottom: 0
              }}
            >
              Voltar
            </button>
          )}

          {assetSelectedIndex !== -1 && (
            <>
              <label style={{ marginRight: 5 }}>Copiar para:</label>
              <CreatableSelect
                isClearable
                className="select"
                onChange={handleCreateCategoria}
                options={categoriasSelect}
                placeholder="Categoria"
                styles={{ width: 100 }}
              />
            </>
          )}

          <button
            onClick={closeModal}
            className="btn btn-default border-radius"
            style={{ marginRight: 5 }}
          >
            Cancelar
          </button>
          <button
            onClick={handleClickInserir}
            className="btn primary border-radius"
            disabled={imageUrl === ''}
            style={imageUrl === '' ? { opacity: 0.5 } : {}}
          >
            Inserir
          </button>
        </Footer>
      </Modal>
    </Container>
  );
}

export default GaleriaImagens;
