import actionsTypes from '../constants/ActionsTypes';

const actions = {
  setGaleriaImagem: (show, onImageSelect, assetType = 'image') => ({
    type: actionsTypes.SET_GALERIA_IMAGEM,
    show,
    onImageSelect,
    assetType
  })
};

export default actions;
